const regions = [
    {
        name: 'Лобня',
        alias: 'lobnya',
        group: 'center'
    },
    {
        name: 'Электроугли',
        alias: 'elektrougli',
        group: 'center'
    },
    {
        name: 'Чехов',
        alias: 'chehov',
        group: 'center'
    },
    {
        name: 'Тула',
        alias: 'tula',
        group: 'center'
    },
    {
        name: 'Калуга',
        alias: 'kaluga',
        group: 'center'
    },
    {
        name: 'Липецк',
        alias: 'lipetsk',
        group: 'center'
    },
    {
        name: 'Тверь',
        alias: 'tver',
        group: 'center'
    },
    {
        name: 'Владимир',
        alias: 'vladimir',
        group: 'center'
    },
    {
        name: 'Рязань',
        alias: 'ryazan',
        group: 'center'
    },
    {
        name: 'Орел',
        alias: 'orel',
        group: 'center'
    },
    {
        name: 'Брянск',
        alias: 'bryansk',
        group: 'center'
    },
    {
        name: 'Курск',
        alias: 'kursk',
        group: 'center'
    },
    {
        name: 'Воронеж',
        alias: 'voronezh',
        group: 'center'
    },
    {
        name: 'Белгород',
        alias: 'belgorod',
        group: 'center'
    },
    {
        name: 'Старый Оскол',
        alias: 'stoskol',
        group: 'center'
    },
    {
        name: 'Ярославль',
        alias: 'yaroslavl',
        group: 'center'
    },
    {
        name: 'Ростов-на-Дону',
        alias: 'rostov-na-donu',
        group: 'south'
    },
    {
        name: 'Новочеркасск',
        alias: 'novocherkassk',
        group: 'south'
    },
    {
        name: 'Краснодар',
        alias: 'krasnodar',
        group: 'south'
    },
    {
        name: 'Ставрополь',
        alias: 'stavropol',
        group: 'south'
    },
    {
        name: 'Пятигорск',
        alias: 'pyatigorsk',
        group: 'south'
    },
    {
        name: 'Нальчик',
        alias: 'nalchik',
        group: 'south'
    },
    {
        name: 'Владикавказ',
        alias: 'vladikavkaz',
        group: 'south'
    },
    {
        name: 'Махачкала',
        alias: 'mahachkala',
        group: 'south'
    },
    {
        name: 'Санкт-Петербург',
        alias: 'sankt-peterburg',
        group: 'north'
    },
    {
        name: 'Череповец',
        alias: 'cherepovets',
        group: 'north'
    },
    {
        name: 'Вологда',
        alias: 'vologda',
        group: 'north'
    },
    {
        name: 'Нижний Новгород',
        alias: 'nizhniy-novgorod',
        group: 'povolzh'
    },
    {
        name: 'Чебоксары',
        alias: 'cheboksary',
        group: 'povolzh'
    },
    {
        name: 'Казань',
        alias: 'kazan',
        group: 'povolzh'
    },
    {
        name: 'Набережные Челны',
        alias: 'naberezhnye-chelny',
        group: 'povolzh'
    },
    {
        name: 'Ульяновск',
        alias: 'ulyanovsk',
        group: 'povolzh'
    },
    {
        name: 'Пенза',
        alias: 'penza',
        group: 'povolzh'
    },
    {
        name: 'Самара',
        alias: 'samara',
        group: 'povolzh'
    },
    {
        name: 'Сызрань',
        alias: 'syzran',
        group: 'povolzh'
    },
    {
        name: 'Саратов',
        alias: 'saratov',
        group: 'povolzh'
    },
    {
        name: 'Волгоград',
        alias: 'volgograd',
        group: 'povolzh'
    },
    {
        name: 'Ижевск',
        alias: 'izhevsk',
        group: 'povolzh'
    },
    {
        name: 'Оренбург',
        alias: 'orenburg',
        group: 'povolzh'
    },
    {
        name: 'Уфа',
        alias: 'ufa',
        group: 'povolzh'
    },
    {
        name: 'Новосибирск',
        alias: 'novosibirsk',
        group: 'syberia'
    }
];

export default regions;