const providers = [
    {
        title: 'Ашинский металлургический завод',
        description: 'Листы',
        imageUrl: process.env.PUBLIC_URL + '/assets/providers/amet.png',
        time: 1500
    },
    {
        title: 'Белорусский металлургический завод',
        description: 'Арматура.',
        imageUrl: process.env.PUBLIC_URL + '/assets/providers/bmz.png',
        time: 1500
    },
    {
        title: 'Евраз',
        description: 'Балка, швеллер, уголок',
        imageUrl: process.env.PUBLIC_URL + '/assets/providers/evraz.png',
        time: 1500
    },
    {
        title: 'МЕЧЕЛ',
        description: 'Листы, трубы',
        imageUrl: process.env.PUBLIC_URL + '/assets/providers/mechel.png',
        time: 1500
    },
    {
        title: 'Магнитогорский металлургический комбинат',
        description: 'Листы, уголок, трубы',
        imageUrl: process.env.PUBLIC_URL + '/assets/providers/mmk.png',
        time: 1500
    },
    {
        title: 'Новолипецкий металлургический комбинат',
        description: 'Листы, арматура',
        imageUrl: process.env.PUBLIC_URL + '/assets/providers/nlmk.png',
        time: 1500
    }
];

export default providers;