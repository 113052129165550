import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import {
    FiCard,
    FiCardActionArea,
    FiCardActions,
    FiCardContent,
    FiCardMedia
} from '../../shared/components/UIElements/FullImageCard/FullImageCard'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        // maxWidth: 345
        marginBottom: '1rem',
    },
    media: {
        height: 140
    },
    fiCardContent: {
        color: '#ffffff',
        backgroundColor: 'rgba(0,0,0,.24)'
    },
    fiCardContentTextSecondary: {
        // color: 'rgba(255,255,255, 0.78)'
        color: 'rgba(255,255,255, 0.78)'
    },
    btn: {
        color: 'white'
    }
}));

const handleClick = () => {
    console.log('clicked');
};

const CardItem = props => {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} sm={12} md={12}>
                <FiCard className={classes.card}>
                    <FiCardActionArea>
                        <FiCardMedia
                            media="picture"
                            alt={props.title}
                            image={props.image}
                            title={props.title}
                        />
                        <FiCardContent className={classes.fiCardContent}>
                            <Typography variant="h3">
                                {props.title}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.fiCardContentTextSecondary}
                                component="p"
                            >
                                {props.textPrimary}
                            </Typography>
                        </FiCardContent>

                        {props.btn1Text && (
                            <FiCardActions className={classes.fiCardContent}>
                                <Button color="secondary" component={RouterLink} variant="outlined" to={props.btn1Path}>
                                    {props.btn1Text}
                                </Button>
                            </FiCardActions>
                        )}
                    </FiCardActionArea>
                </FiCard>
            </Grid>
        </>
    )
}

export default CardItem;