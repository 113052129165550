import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: 250,
        background: 'rgba(255,255,255,0.9)',
        margin: '20px'
    },
    media: {
        height: 150,
        marginTop: '10px',
        textAlign: 'center'
    },
    title: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '1rem',
        color: '#000'
    },
    desc: {
        fontFamily: 'Nunito',
        fontSize: '1rem',
        color: '#ccc'
    }
});

export default function ImageCard({ place }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            
            <div className={classes.media}>
                <img src={place.imageUrl} />
            </div>
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h1"
                    component="h2"
                    className={classes.title}
                >
                    {place.title}
                </Typography>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.desc}
                >
                    {place.description}
                </Typography>
            </CardContent>
        </Card>
    );
}