import React, { useState } from 'react';
import { useForm } from '../../hooks/myform-hook';
import { useHttpClient } from '../../hooks/http-hook';

import {
    Button, Card, CardActionArea, CardActions,
    CardContent, CardMedia, Container, Grid,
    Paper, Typography,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputAdornment
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    callbackCard: {
        position: "relative",
        // color: theme.palette.common.black,
        marginBottom: theme.spacing(4),
    },
    cardGrid: {
        margin: theme.spacing(3),
    }
}));


const CallbackCard = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const { formData, isValid, handleInputChange } = useForm(
        {
            phone: {
                value: '',
                isValid: false
            }
        }
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpen(false);
        console.log("phone: " + formData.phone);
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/callback`,
                'POST',
                JSON.stringify({
                    phone: formData.phone,
                }),
                {
                    'Content-Type': 'application/json'
                }
            );
            // history.push('/page/' + pageId + '/articles/' + articleId);
            // history.push('/page/' + pageId + '/articles/');
            // history.push('/pages');
            console.log('Письмо отправлено. ' + response);
        } catch (err) { console.log('ОШИБКА::: ' + err) }
    };

    return (
        <>
            <Paper variant="outlined" className={classes.callbackCard}>
                <Container maxWidth="md">
                    <Grid container align="center">
                        <Grid item md={12} sm={12} xs={12} className={classes.cardGrid}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleClickOpen}
                            >
                                Перезвоните мне
                        </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>

            <Dialog
                open={open}
                onClose={handleSubmit}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle>Введите номер телефона</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Перезвоним вам через 60 секунд:
                    </DialogContentText>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="phone"
                            name="phone"
                            // label="+7 (999) 123-45-67"
                            onChange={handleInputChange}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+7</InputAdornment>
                            }}
                        />
                        <Button align="center" variant="contained" type="submit" disabled={!isValid} color="primary">Перезвоните мне</Button>
                    </form>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </>
    );
}

export default CallbackCard;