import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { RegionContext } from '../../context/region-context';

import regions from '../../../static/regions';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'; // Region Toggler

import {
    Box,
    Button,
    IconButton,
    Grid,
    Link,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // width: '300px',
        backgroundColor: theme.palette.background.paper,
    },
    regionTitle: {
        color: 'grey',
        textDecorationLine: 'underline',
        textDecorationStyle: 'dotted',
        [theme.breakpoints.down(600)]: {
            display: 'none'
        }
    },
    regionTitleNoHide: {
        color: 'grey',
        textDecorationLine: 'underline',
        textDecorationStyle: 'dotted',
    },
    modal: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '90%',
        maxWidth: '600px'
    },
    tab: {
        width: '100%',

    },
    regionItemModal: {

    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const RegionToggleModal = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const region = useContext(RegionContext);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // console.log('globalRegions = ' + region.regions.name);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRegionSubmit = (rName, rAlias) => {
        setOpen(false);
        // console.log(rName + ' ' + rAlias);
        region.loginRegion(rName, rAlias);
        // console.log(region.regionName);
    }

    const populateRegions = (group) => {
        return (
            <Grid container>
                {regions
                    .filter(region => (region.group === group))
                    .map((region, key) => (
                        <Grid item xs={6} key={key} component={Button} onClick={() => handleRegionSubmit(region.name, region.alias)}>
                            {region.name}
                        </Grid>
                    ))}
            </Grid>
        );
    };

    return (
        <div className={classes.root}>
            <IconButton component={Link} onClick={handleOpen}>
                <RoomOutlinedIcon>
                </RoomOutlinedIcon>
                <Typography className={classes.regionTitle} variant="subtitle1">
                    {region.regionName}
                </Typography>
            </IconButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Филиал:</h2>

                        <Tabs className={classes.tab}
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Центр" {...a11yProps(0)} />
                            <Tab label="Южный регион" {...a11yProps(1)} />
                            <Tab label="Северо-Запад" {...a11yProps(2)} />
                            <Tab label="Поволжье" {...a11yProps(3)} />
                            <Tab label="Сибирь" {...a11yProps(4)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            {populateRegions('center')}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {populateRegions('south')}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {populateRegions('north')}
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {populateRegions('povolzh')}
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            {populateRegions('syberia')}
                        </TabPanel>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

export default RegionToggleModal;