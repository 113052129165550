import { useState } from 'react';

export const useForm = (initialState = {}) => {
    const [formData, setFormData] = useState(initialState);
    const [isValid, setIsValid] = useState(false);

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
        console.log (event.target.value.length);
        if (event.target.value.length === 10) {
            setIsValid(true);
        }
    }

    // const handleSubmit = event => {
    //     event.preventDefault();
    //     // onSubmit?.(formData);
    //     console.log('formData = ' + formData.title + formData.subtitle + formData.body);
    // };

    return { formData, isValid, handleInputChange };
}