import { useState, useCallback, useEffect } from 'react';

export const useRegion = () => {
    const [regionName, setRegionName] = useState('Лобня');
    const [regionAlias, setRegionAlias] = useState('lobnya');

    const loginRegion = useCallback(
        (rName, rAlias) => {
            const storedData = JSON.parse(localStorage.getItem('regionData'));

            if (!storedData) { // если регион не выбран
                const rName = 'Лобня';
                const rAlias = 'lobnya';
                localStorage.setItem(
                    'regionData',
                    JSON.stringify({
                        regionName: rName,
                        regionAlias: rAlias
                    })
                );
                setRegionName(rName);
                setRegionAlias(rAlias);
            }

            console.log('loginRegion: storedData= ' + storedData);

            setRegionName(rName);
            setRegionAlias(rAlias);

            localStorage.setItem(
                'regionData',
                JSON.stringify({
                    regionName: rName,
                    regionAlias: rAlias
                })
            )
        },
        [],
    );

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('regionData'));
        if (storedData) {
            loginRegion(storedData.regionName, storedData.regionAlias);
        }
    }, [loginRegion]);

    return { loginRegion, regionName, regionAlias };
};