import React, { useState, useContext } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';

import RegionToggleModal from '../NavigationMUI/RegionToggleModal';

import { makeStyles } from '@material-ui/core/styles';
import MobileRightMenuSlider from '@material-ui/core/Drawer';
import {
    Apps,
    AssignmentInd,
    ContactMail,
    Home,
    PhoneIphoneOutlined
} from '@material-ui/icons';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
// import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'; // Region Toggler
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'; // Обновить каталог
import MenuBookIcon from '@material-ui/icons/MenuBook'; // Страницы
import PostAddIcon from '@material-ui/icons/PostAdd'; // Добавить новый филиал

import {
    Box,
    Divider,
    IconButton,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
    Typography
} from '@material-ui/core';

import logo from '../NavigationMUI/static/avatar.png';
import navbarLogo from '../NavigationMUI/static/navbar_logo2.png';

import { AuthContext } from '../../context/auth-context';

const useStyles = makeStyles(theme => ({
    menuSliderContainer: {
        width: 280,
        background: '#511',
        height: '100%'
    },
    menuSliderLogo: {
        display: 'block',
        margin: '0.5rem auto',
        maxWidth: 150
    },
    menuButton: {
        padding: '0 0',
    },
    navbarLogo: {
        maxWidth: 100,
    },
    listItem: {
        color: 'tan'
    },
    phone: {
        // background: 'grey',
    },
    regionToggler: {
        // background: 'pink',
    },
    typoActionButtons: {

    }

}));

const menuItems = [
    {
        listIcon: <Home />,
        listText: 'Главная',
        listPath: '/'

    },
    {
        listIcon: <AssignmentInd />,
        listText: 'Металлопрокат',
        listPath: '/categories'

    },
    {
        listIcon: <Apps />,
        listText: 'Филиалы',
        listPath: '/regions'

    },
    {
        listIcon: <ContactMail />,
        listText: 'О компании',
        listPath: '/about'

    }
];

const NavBar = () => {
    const auth = useContext(AuthContext);
    const [state, setState] = useState({
        right: false
    });

    const toggleSlider = (slider, open) => () => {
        setState({ ...state, [slider]: open });
    };

    const classes = useStyles();

    const sideList = slider => (
        <Box
            className={classes.menuSliderContainer}
            component="div"
            onClick={toggleSlider(slider, false)}
        >
            {/*<Avatar className={classes.avatar} src={avatar} />*/}
            <img src={logo} alt="Металлоторг" className={classes.menuSliderLogo} />
            <Divider />
            <List>
                {menuItems.map((lsItem, key) => (
                    <ListItem button key={key} component={Link} href={lsItem.listPath}>
                        <ListItemIcon className={classes.listItem}>
                            {lsItem.listIcon}
                        </ListItemIcon>
                        <ListItemText
                            className={classes.listItem}
                            primary={lsItem.listText}
                        />
                    </ListItem>
                ))}
                <br />
                <ListItem button component={Link} href="tel:8 (495) 727-07-04">
                    <ListItemIcon className={classes.listItem}>
                        <PhoneIcon />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItem}
                        primary="8 (495) 727-07-04"
                    />
                </ListItem>
                <ListItem button component={Link} href="tel:8 (926) 407-11-22">
                    <ListItemIcon className={classes.listItem}>
                        <PhoneIphoneOutlined />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItem}
                        primary="8 (926) 407-11-22"
                    />
                </ListItem>
                <ListItem button component={Link} href="mailto:7270704@metallotorg.ru">
                    <ListItemIcon className={classes.listItem}>
                        <MailOutlineIcon />
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItem}
                        primary="7270704@metallotorg.ru"
                    />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <React.Fragment>
            <Grid container spacing={1} justify="center" alignItems="center">
                <Grid container item xs={4}>
                    <IconButton className={classes.menuButton} onClick={toggleSlider("right", true)}>
                        <MenuIcon style={{ color: "tomato" }} />
                    </IconButton>
                </Grid>
                <Grid container item xs={4} justify="flex-start">
                    <Link component={RouterLink} to="/">
                        <img src={navbarLogo} alt="Металлоторг" className={classes.navbarLogo} />
                    </Link>
                </Grid>
                <Grid container item xs={4} justify="flex-end">
                    <Grid className={classes.phone} item xs={6}>
                        <IconButton href="tel:8(926) 407-11-22">
                            <PhoneIphoneOutlined>
                            </PhoneIphoneOutlined>
                        </IconButton>
                    </Grid>
                    <Grid className={classes.regionToggler} item xs={6}>
                        <RegionToggleModal />
                    </Grid>
                </Grid>
            </Grid>
            {auth.isLoggedIn && (

                <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid container item xs={3} justify="flex-end">
                        <IconButton>
                            <NavLink to="/catalog" exact>
                                <PlaylistAddCheckIcon>
                                </PlaylistAddCheckIcon>
                                <Typography variant="subtitle2">
                                    Обновить прайс
                                </Typography>
                            </NavLink>
                        </IconButton>
                    </Grid>
                    <Grid container item xs={1} justify="flex-end">
                        <IconButton>
                            <NavLink to="/pages" exact>
                                <MenuBookIcon>
                                </MenuBookIcon>
                                <Typography variant="subtitle2">
                                    Страницы
                                </Typography>
                            </NavLink>
                        </IconButton>
                    </Grid>
                    <Grid container item xs={2} justify="flex-end">
                        <IconButton>
                            <NavLink to="/pages/addpage" exact>
                                <PostAddIcon>
                                </PostAddIcon>
                                <Typography variant="subtitle2">
                                    Добавить страницу
                                </Typography>
                            </NavLink>
                        </IconButton>
                    </Grid>
                    <Grid container item xs={3} justify="flex-end">
                        <IconButton>
                            <NavLink to="/regions/addregion" exact>
                                <PostAddIcon>
                                </PostAddIcon>
                                <Typography variant="subtitle2">
                                    Добавить филиал
                                </Typography>
                            </NavLink>
                        </IconButton>
                    </Grid>
                    <Grid container item xs={3} justify="flex-end">
                        <IconButton onClick={auth.logout}>
                            <ExitToAppIcon>
                            </ExitToAppIcon>
                            <Typography variant="subtitle2">
                                Выход
                            </Typography>
                        </IconButton>
                    </Grid>
                </Grid>


            )}

            <MobileRightMenuSlider
                anchor="left"
                open={state.right}
                onClose={toggleSlider("right", false)}
            >
                {sideList("right")}
            </MobileRightMenuSlider>
        </React.Fragment>
    )
};
export default NavBar;