import React from 'react';
import CardList from './CardList'

const loadedCards = [
    {
        title: 'Металлопрокат',
        textPrimary: 'Более 30 000 позиций в наличии на складе.',
        image: 'assets/cards/metalloprokat.jpg',
        btn1Text: 'В каталог',
        btn1Path: '/categories'
    },
    {
        title: 'Точная резка',
        textPrimary: 'Резка на лентопильном станке',
        image: 'assets/cards/lentopil_stanok.jpg',
        btnText: 'Подробнее'
    },
    {
        title: 'Рубка листов на гильотине',
        textPrimary: 'Рубка листов в размер на гильотинных ножницах',
        image: 'assets/cards/gilotina_rubka.jpg',
        btnText: 'Подробнее'
    },
    {
        title: 'Плазменная резка металла',
        textPrimary: 'Резка на лентопильном станке',
        image: 'assets/cards/plazmennaya_rezka.jpg',
        btnText: 'Подробнее'
    }
]
const Cards = () => {
    return (
        <>
            <CardList items={loadedCards} />
        </>
    )
}

export default Cards;