import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";


const MainFooter = () => {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <div style={{/* maxWidth: 700, */margin: "auto", textAlign: "center" }}>
            <Typography variant="caption" align={"center"}>
                АО "Металлоторг" {getCurrentYear()}. Продажа черного металлопроката
                <h3>Отдел продаж:</h3>
                <p>8 (495) 727-07-04</p>
                <p>8 (926) 407-11-22</p>
                <p><b>Адрес офиса:</b> Москва, ул. Кусковская, д.20А</p>
            </Typography>
            <Divider style={{ margin: "24px auto", width: 60 }} />
            <Grid container justify={"center"} spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography align={"center"} gutterBottom color={"textSecondary"}>
                        <Link href="/" >
                            Главная
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography align={"center"} gutterBottom color={"textSecondary"}>
                        <Link href="/categories" >
                            Каталог
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography align={"center"} gutterBottom color={"textSecondary"}>
                        <Link href="/regions" >
                            Металлобазы
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Typography align={"center"} gutterBottom color={"textSecondary"}>
                        <Link href="/about" >
                            О компании
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default MainFooter;