import React from 'react';

import NavBar from './Navbar';

const MainNavigationMUI = () => {
    return (
        <div>
            <NavBar />
            {/*<Header />*/}
        </div>
    )
}

export default MainNavigationMUI;