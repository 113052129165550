import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Button from '../../shared/components/FormElements/Button';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ImageUpload from '../../shared/components/FormElements/ImageUpload';

import { useForm } from '../../shared/hooks/form-hook';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import './CategoryForm.css';

const UploadCategoryImage = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const categoryId = useParams().categoryId;
    const [formState, inputHandler] = useForm(
        {
            image: {
                value: null,
                isValid: false
            }
        },
        false
    );
    const history = useHistory();

    const submitHandler = async event => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('image', formState.inputs.image.value);
            await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/categories/${categoryId}/uploadImage`, 'POST', formData, {
                Authorization: 'Bearer ' + auth.token
            });
            history.push('/categories');
        } catch (err) { }
    };

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <form className="category-form" onSubmit={submitHandler}>
                {isLoading && <LoadingSpinner asOverlay />}
                <ImageUpload
                    id="image"
                    onInput={inputHandler}
                    errorText="Please provide an image."
                />
                <Button type="submit" disabled={!formState.isValid}>
                    Добавить изображение
                </Button>
            </form>
        </React.Fragment>
    );
};

export default UploadCategoryImage;