import React from 'react';

import CardItem from './CardItem';

import { Container, Grid } from '@material-ui/core';

const CardList = (props) => {
    return (
        <>
            <div>
                <Container maxWidth="md">
                    <Grid container>
                        {props.items.map(card => {
                            return (
                                <CardItem
                                    key={card.id}
                                    title={card.title}
                                    textPrimary={card.textPrimary}
                                    image={card.image}
                                    btn1Text={card.btn1Text}
                                    btn1Path={card.btn1Path}
                                />
                            )
                        })}
                    </Grid>
                </Container>
            </div>

        </>
    )
}

export default CardList;