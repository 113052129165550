import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Button, Card, CardActionArea, CardActions,
    CardContent, CardMedia, Container, Grid,
    Paper, Typography
} from '@material-ui/core';

// import Card from '../../shared/components/UIElements/Card';

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '1rem 0 1rem 0'
    },
    mainFeaturesPost: {
        position: "relative",
        color: theme.palette.common.black,
        marginBottom: theme.spacing(2),
    },
    mainFeaturesPostContent: {
        position: "relative",
        padding: theme.spacing(6),
        [theme.breakpoints.down(600)]: {
            padding: theme.spacing(0),  
        },
        marginTop: theme.spacing(2)
    },
    additionalFeaturesPostContent: {
        position: "relative",
        padding: theme.spacing(6),
        marginTop: theme.spacing(2)
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center'

    },
    child: {
        width: '50%',
        // flex: '1 1 25%'
    },
    textStyleBig: {
        fontSize: '3rem'
    },
    cardMedia: {
        paddingTop: "56.25%"
    },
    cardContent: {
        flexGrow: 1
    },
    cardGrid: {
        marginTop: theme.spacing(4)
    }

}));

const card1Title = 'Компания Металлоторг';
const card1Text = 'Одна из крупнейших компаний по продаже черного металлопроката в России.';

const cards = [
    {
        name: '1',
        textPrimary: 40,
        textSecondary: 'металлобаз',
        image: 'assets/about/metallobaza.jpg',
        btnText: 'Филиалы'
    },
    {
        name: '2',
        textPrimary: 2500,
        textSecondary: 'позиций металлопроката',
        image: 'assets/about/metalloprokat.jpg',
        btnText: 'Перейти в каталог'
    },
    {
        name: '3',
        textPrimary: 5,
        textSecondary: 'видов обработки металла',
        image: 'assets/about/obrabotka.jpg',
        btnText: 'Смотреть услуги'
    },
    {
        name: '4',
        textPrimary: 10,
        textSecondary: 'филиалов с собственным производством',
        image: 'assets/about/proizvodstvo.jpg',
        btnText: 'Производство'
    },
];

const AboutCard = () => {
    const classes = useStyles();


    return (
        <>
            <main>
                <Paper className={classes.mainFeaturesPost}
                    style={{ backgroundImage: `url(https://source.unsplash.com/random)` }}>
                    <Container
                        maxWidth="md"
                        // fixed
                    >
                        <Grid container>
                            <Grid item md={6}>
                                <div className={classes.mainFeaturesPostContent}>
                                    <Typography
                                        component="h1"
                                        variant="h3"
                                        color="inherit"
                                        gutterBottom
                                    >
                                        {card1Title}
                                    </Typography>
                                    <Typography
                                        component="h5"
                                        color="inherit"
                                        paragraph
                                    >
                                        {card1Text}
                                    </Typography>
                                    <Button variant="contained" color="secondary">
                                        Learn more
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
                <div className={classes.mainContent}>
                    <Container maxWidth="md">
                        <Typography
                            variant="h3"
                            align="center"
                            color="textPrimary"
                            gutterBottom
                        >
                            Преимущества
                        </Typography>
                        <div className={classes.mainButtons}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <Button variant="contained" color="primary">Start Now</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" color="primary">Learn More</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    <Grid container spacing={4}>
                        {cards.map((card) => (
                            <Grid item key={card.name} xs={12} sm={6} md={6}>
                                <Card className={classes.card}>
                                    <CardMedia
                                        className={classes.cardMedia}
                                        image={card.image}
                                        title={card.textPrimary}
                                    />
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="h3" gutterBottom>
                                            {card.textPrimary}
                                        </Typography>
                                        <Typography variant="h5" gutterBottom>
                                            {card.textSecondary}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            {card.btnText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                <Paper className={classes.mainFeaturesPost}
                /*style={{ backgroundImage: `url(https://source.unsplash.com/random)` }}*/>
                    <Container
                        // maxWidth="md"
                        fixed
                    >
                        <Grid container>
                            <Grid item md={12}>
                                <div className={classes.mainFeaturesPostContent}>
                                    <Typography
                                        component="h1"
                                        variant="h3"
                                        color="inherit"
                                        gutterBottom
                                    >
                                    Отгрузка металлопроката российским компаниям
                                    </Typography>
                                    <Typography
                                        component="h5"
                                        color="inherit"
                                        paragraph
                                    >
                                    Выдаем полный пакет документов: счет-фактуру, товарную накладную, товарно-транспортную 
                                    накладую и сертификат завода-изготовителя
                                    </Typography>
                                    <Button variant="contained" color="secondary">
                                        Подробнее
                                </Button>
                                </div>
                            </Grid>
                            <Grid item md={12}>
                                <div className={classes.mainFeaturesPostContent}>
                                    <Typography
                                        component="h1"
                                        variant="h3"
                                        color="inherit"
                                        gutterBottom
                                    >
                                    Отгрузка в страны Таможенного союза
                                    </Typography>
                                    <Typography
                                        component="h5"
                                        color="inherit"
                                        paragraph
                                    >
                                    Продажа металлопроката клиентам из Белоруссии, Казахстана и Армении
                                    </Typography>
                                    <Button variant="contained" color="secondary">
                                        Подробнее
                                </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </main>
        </>
    )
}

export default AboutCard;