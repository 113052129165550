import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green, orange } from '@material-ui/core/colors';
import 'fontsource-roboto';
import Container from '@material-ui/core/Container';


import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import { useRegion } from './shared/hooks/region-hook';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import About from './about/pages/About';
// import WhatsAppChat from './shared/components/UIElements/WhatsAppChat';
import UploadCategoryImage from './categories/pages/UploadCategoryImage';
import MainFooter from './shared/components/Navigation/Footer/MainFooter';
import MainNavigationMUI from './shared/components/NavigationMUI/MainNavigationMUI';
import { RegionContext } from './shared/context/region-context';
import CallbackCard from './shared/components/UIElements/CallbackCard';
import Cards from './homepage/components/Cards';

const Homepage = React.lazy(() => import('./homepage/components/Homepage'));
const NewPage = React.lazy(() => import('./pages/pages/NewPage'));
const Pages = React.lazy(() => import('./pages/pages/Pages'));
const PageArticles = React.lazy(() => import('./articles/pages/PageArticles'));
const ArticleDetails = React.lazy(() => import('./articles/pages/ArticleDetails'));
const NewArticle = React.lazy(() => import('./articles/pages/NewArticle'));
const RegionInfo = React.lazy(() => import('./homepage/components/RegionInfo'));
// const Users = React.lazy(() => import('./user/pages/Users'));
const Regions = React.lazy(() => import('./regions/pages/Regions'));
const NewRegion = React.lazy(() => import('./regions/pages/NewRegion'));
const UpdateRegion = React.lazy(() => import('./regions/pages/UpdateRegion'));
const UploadImage = React.lazy(() => import('./regions/pages/UploadImage'));
const RegionDetails = React.lazy(() => import('./regions/pages/RegionDetails'));
const Categories = React.lazy(() => import('./categories/pages/Categories'));
const NewCategory = React.lazy(() => import('./categories/pages/NewCategory'));
const SendMail = React.lazy(() => import('./shared/components/UIElements/SendMail'));
const UpdateCategory = React.lazy(() => import('./categories/pages/UpdateCategory'));
const UpdateCatalog = React.lazy(() => import('./catalog/pages/UpdateCatalog'));
const CategoryDetails = React.lazy(() => import('./categories/pages/CategoryDetails'));
const NewPlace = React.lazy(() => import('./places/pages/NewPlace'));
const UserPlaces = React.lazy(() => import('./places/pages/UserPlaces'));
const UpdatePlace = React.lazy(() => import('./places/pages/UpdatePlace'));
const Auth = React.lazy(() => import('./user/pages/Auth'));
// const MainFooter = React.lazy(() => import('./shared/components/Navigation/Footer/MainFooter'));

const theme = createMuiTheme({
    typography: {
        h3: {
            fontSize: 24,
            marginBottom: 16,
        },
        h4: {
            fontSize: 20,
            marginBottom: 16,
        },
        h5: {
            fontSize: 18,
            // color: 'green'
        },
        h6: {
            fontSize: 12,
            // color: 'green'
        },
        subtitle1: {
            fontSize: 12,
            // color: 'red',
        },
        subtitle2: {
            fontSize: 8,
            // color: 'red',
        }
    },
    palette: {
        primary: {
            main: green[500]
        },
        secondary: {
            main: orange[500]
        }
    },

});

const App = () => {
    const { token, login, logout, userId } = useAuth();

    const { loginRegion, regionName, regionAlias } = useRegion();
    console.log('App: regionName = ' + regionName);

    let routes;

    if (token) {
        routes = (
            <Switch>
                <Route path="/" exact>
                    <CallbackCard />
                    <Cards />
                    <RegionInfo />
                    <SendMail />
                    <Categories />
                    <Homepage />
                </Route>
                <Route path="/pages" exact>
                    <Pages />
                </Route>
                <Route path="/pages/addpage" exact>
                    <NewPage />
                </Route>
                {/*<Route path="/page/:alias/articles" exact>
                    <PageDetails />
                </Route> */}
                <Route path="/page/:pageId/articles" exact>
                    <PageArticles />
                </Route>
                <Route path="/page/:pageId/articles/new-article" exact>
                    <NewArticle />
                </Route>
                <Route path="/page/:pageId/articles/:articleId" exact>
                    <ArticleDetails />
                </Route>
                <Route path="/catalog" exact>
                    <UpdateCatalog />
                </Route>
                <Route path="/categories" exact>
                    <SendMail />
                    <Categories userId={userId} />
                </Route>
                <Route path="/categories/addcategory" exact>
                    <NewCategory />
                </Route>
                <Route path="/categories/:categoryId" exact>
                    <UpdateCategory />
                </Route>
                <Route path="/categories/:categoryId/uploadImage" exact>
                    <UploadCategoryImage />
                </Route>
                <Route path="/category/:alias" exact>
                    <CategoryDetails />
                </Route>
                <Route path="/category/:alias/:subalias" exact>
                    <CategoryDetails />
                </Route>
                <Route path="/regions" exact>
                    <Regions />
                </Route>
                <Route path="/regions/addregion" exact>
                    <NewRegion />
                </Route>
                <Route path="/regions/:regionId" exact>
                    <UpdateRegion />
                </Route>
                <Route path="/regions/:regionId/uploadImage" exact>
                    <UploadImage />
                </Route>
                <Route path="/region/:alias" exact>
                    <RegionDetails />
                </Route>
                <Route path="/about" exact>
                    <About />
                </Route>
                <Route path="/:userId/places" exact>
                    <UserPlaces />
                </Route>
                <Route path="/places/new" exact>
                    <NewPlace />
                </Route>
                <Route path="/places/:placeId">
                    <UpdatePlace />
                </Route>
                <Redirect to="/" />
            </Switch>
        );
    } else {
        routes = (
            <Switch>
                <Route path="/" exact>
                    <CallbackCard />
                    <Cards />
                    <RegionInfo />
                    <SendMail />
                    <Categories />
                    <Homepage />
                </Route>
                <Route path="/pages" exact>
                    <Pages />
                </Route>
                <Route path="/categories" exact>
                    <SendMail />
                    <Categories />
                </Route>
                <Route path="/category/:alias" exact>
                    <CategoryDetails />
                </Route>
                <Route path="/category/:alias/:subalias" exact>
                    <CategoryDetails />
                </Route>
                <Route path="/regions" exact>
                    <Regions />
                </Route>
                <Route path="/region/:alias" exact>
                    <RegionDetails />
                </Route>
                <Route path="/about" exact>
                    <About />
                </Route>
                {<Route path="/:userId/places" exact>
                    <UserPlaces />
                </Route>}
                <Route path="/auth">
                    <Auth />
                </Route>
                <Redirect to="/auth" />
            </Switch>
        );
    }


    return (

        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                login: login,
                logout: logout
            }}
        >
            <RegionContext.Provider
                value={{
                    regionName: regionName,
                    regionAlias: regionAlias,
                    loginRegion: loginRegion
                }}
            >

                <ThemeProvider theme={theme}>

                    <div className="App">
                        <Router>
                            <Container maxWidth="md">
                                {/*<MainNavigation />*/}
                                {<MainNavigationMUI />}

                                <main>
                                    <Suspense fallback={
                                        <div className="center">
                                            <LoadingSpinner />
                                        </div>
                                    }
                                    >
                                        {routes}
                                    </Suspense>
                                </main>
                                <MainFooter />
                            </Container>
                        </Router>
                    </div>

                </ThemeProvider>
            </RegionContext.Provider>
        </AuthContext.Provider>
    );

};

export default App;
