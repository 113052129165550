import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageCardSimple2 from '../../shared/components/UIElements/ImageCardSimple2';

import providers from '../../static/providers';

// import Card from '../../shared/components/UIElements/Card';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        },
        child: {
            flex: '1 1 25%'
        }
    }

}));

const OurProviders = () => {
    const classes = useStyles();

    return (
        <div className={classes.root} id="place-to-visit">
            <ImageCardSimple2 className={classes.child} place={providers[0]} />
            <ImageCardSimple2 className={classes.child} place={providers[1]} />
            <ImageCardSimple2 className={classes.child} place={providers[2]} />
            <ImageCardSimple2 className={classes.child} place={providers[3]} />
            <ImageCardSimple2 className={classes.child} place={providers[4]} />
            <ImageCardSimple2 className={classes.child} place={providers[5]} />
        </div>
    )
}

export default OurProviders;