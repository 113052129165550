import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';

import CompanyDetailsTable from './CompanyDetailsTable';
import PaymentDetailsSovkombank from './PaymentDetailsSovkombank';
import PaymentDetailsSber from './PaymentDetailsSber';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         minHeight: '100vh',
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover'

//     }

// }));

const CompanyDetails = () => {
    // const classes = useStyles();

    return (
        <div>
            <h2>Реквизиты АО "Металлоторг"</h2>
            <CompanyDetailsTable />
            <h2>Банковские реквизиты</h2>
            <PaymentDetailsSovkombank />
            <PaymentDetailsSber />
        </div>
    )
}

export default CompanyDetails;